<template>
  <VueApexCharts
    ref="chart"
    type="bar"
    width="100%"
    height="339"
    :options="chartOptions"
    :series="series"
  >
  </VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartDate: {
      type: Array,
    },
    refData: {
      type: Array,
    },
  },
  data() {
    return {
      series: this.refData,
      //  [
      //   {
      //     name: "서울시",
      //     data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      //   },
      //   {
      //     name: "부산광역시",
      //     data: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      //   },
      //   {
      //     name: "대구광역시",
      //     data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      //   },
      //   {
      //     name: "인천광역시",
      //     data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      //   },
      //   {
      //     name: "광주광역시",
      //     data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      //   },
      //   {
      //     name: "대전광역시",
      //     data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      //   },
      //   {
      //     name: "울산광역시",
      //     data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      //   },
      //   {
      //     name: "세종특별자치시",
      //     data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      //   },
      //   {
      //     name: "경기도",
      //     data: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      //   },
      //   {
      //     name: "강원도",
      //     data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      //   },
      //   {
      //     name: "충청북도",
      //     data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      //   },
      //   {
      //     name: "충청남도",
      //     data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      //   },
      //   {
      //     name: "전라북도",
      //     data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      //   },
      //   {
      //     name: "전라남도",
      //     data: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      //   },
      //   {
      //     name: "경상북도",
      //     data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      //   },
      //   {
      //     name: "경상남도",
      //     data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      //   },
      //   {
      //     name: "제주시",
      //     data: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      //   },
      // ],
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: this.chartDate,
          labels: {
            rotate: 45,
            rotateAlways: true,
            style: {
              fontSize: "10px",
              colors: "#808080",
            },
            offsetY: 30,
            offsetX: 10,
          },
          axisTicks: {
            show: false,
          },
        },
        crosshairs: {
          show: false,
        },
        legend: {
          position: "bottom",
          fontSize: "10px",
          markers: {
            fillColors: [
              "#0790FF",
              "#E34307",
              "#FF9D00",
              "#50B93C",
              "#47C1BF",
              "#CF0893",
              "#0753FF",
              "#FFBA00",
              "#6EBFFF",
              "#FF53AF",
              "#89E660",
              "#8D19FF",
              "#FF6200",
              "#01ED77",
              "#D69236",
              "#cc1414",
              "#21b5aa",
            ],
          },
          labels: {
            colors: "#808080",
          },
          itemMargin: {
            horizontal: 16,
            vertical: 4,
          },
        },

        fill: {
          colors: [
            "#0790FF",
            "#E34307",
            "#FF9D00",
            "#50B93C",
            "#47C1BF",
            "#CF0893",
            "#0753FF",
            "#FFBA00",
            "#6EBFFF",
            "#FF53AF",
            "#89E660",
            "#8D19FF",
            "#FF6200",
            "#01ED77",
            "#D69236",
            "#cc1414",
            "#21b5aa",
          ],
        },
        yaxis: {
          min: 0,
          max: 40,
          tickAmount: 8,
          labels: {
            style: {
              fontSize: "10px",
              colors: "#808080",
            },
          },
        },
      },
    };
  },
  mounted() {
    this.updateData();
  },
  watch: {
    chartDate: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.$refs.chart.updateOptions({
        series: this.refData,
        xaxis: {
          type: "category",
          categories: this.chartDate,
        },
      });
    },
  },
};
</script>
